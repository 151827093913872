<template>
  <div class="e-content">
    <div id="LayoutComposition">
      <h1>Layout</h1>
      <p>enterBlock 홈페이지는 반응형으로 제작되어 PC와 Mobile 둘 다에서 이용 할 수 있습니다.
      </p>
      <b-card title="Layout Composition">
        <b-card-text>레이아웃에는 2가지 타입이 있습니다.
          <ul class="my-1">
            <li>Full layout</li>
            <li>Main layout</li>
          </ul>
          <p><span class="font-weight-bold">Full layout</span>은 존재하지 않는 웹 주소에 들어갔을 때 표쇠디는 오류 페이지에 사용되었습니다. 네비바, 네비게이션
            메뉴, 푸터 등이 없는 빈 페이지 입니다.<span class="font-weight-bold">Main Layout</span>은 모든 페이지에 사용되고 있으며 헤더와 콘텐츠, 푸터로 이루어져
            있습니다.</p>
        </b-card-text>
        <b-img class="enter-layout-img" width="400" center src="@/assets/images/code/layout.jpg"></b-img>
        <div class="mt-2">
          <div class="d-flex justify-content-around">
            <b-button
                class="btn btn-warning"
                :class="visible ? null : 'collapsed'"
                :aria-expanded="visible ? 'true' : 'false'"
                aria-controls="collapse-1"
                @click="visible = !visible"
            >
              Full layout 코드 보기
            </b-button>
            <b-button
                class="btn btn-warning"
                :class="visible2 ? null : 'collapsed'"
                :aria-expanded="visible2 ? 'true' : 'false'"
                aria-controls="collapse-2"
                @click="visible2 = !visible2"
            >
              Main layout 코드 보기
            </b-button>
          </div>
          <b-collapse id="collapse-1" v-model="visible" class="mt-2">
            <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                {{ codeFullLayout }}
                </code>
              </pre>
            </div>
          </b-collapse>
          <b-collapse id="collapse-2" v-model="visible2" class="mt-2">
            <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                {{ codeMainLayout }}
                </code>
              </pre>
            </div>
          </b-collapse>
        </div>

      </b-card>

      <b-card-code id="Header" title="Header">
        <p>
          헤더의 경우 PC 버전과 Mobile 버전이 있습니다.
        </p>
        <p><span class="font-weight-bold">PC 버전:</span> 상단 좌측에는 로고, 상단 우측에는 네비게이션이 있습니다.</p>
        <p><span class="font-weight-bold">Mobile 버전:</span> 상단 좌측에는 로고, 상단 우측에는 횀버거 메뉴 아이콘이 있어 클릭시 네비게이션이 나타납니다.</p>
        <h5 class="mb-2">Code Characteristics</h5>
        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold" header="<component :is />"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">동적 컴포넌트를 사용하여 메뉴에 setup()에 설정해둔 resolveNavComponent를 가져옵니다. resolveNavComponent는
            네비게이션 메뉴를 전달받은 NavMenuHeaderLink.vue를 반환합니다.
          </b-card-text>
        </b-card>
        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="v-if, v-show"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">화면크기에 따라 업데이트된 isMobile을 체크하여 <code>v-if</code>로 PC와 Mobile을 보여줍니다. Mobile에서는
            v-show를 사용하여 햄버거 메뉴 클릭시 <code>isShow</code>를 통해 네비게이션을 로드합니다.
          </b-card-text>
        </b-card>

        <template #code>
          {{ codeHeader }}
        </template>
      </b-card-code>

      <b-card-code id="Footer" title="Footer">
        <b-card-text>페이지 하단의 엔터블록 로고와 간략한 정보가 표시됩니다.
        </b-card-text>

        <template #code>
          {{ codeFooter }}
        </template>
      </b-card-code>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg, BButton, BCollapse} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeFooter,
  codeFullLayout, codeHeader, codeMainLayout,
} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BImg,
    BButton,
    BCollapse,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['LayoutComposition', 'Header', 'Footer'],
      visible: false,
      visible2: false,
      codeFullLayout,
      codeMainLayout,
      codeHeader,
      codeFooter
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
    console.log('ddd', this.$refs.Routing)
  },
}
</script>

<style>
@media (max-width: 493px) {
  .enter-layout-img {
    width: 100%;
    min-width: 70px
  }
}
</style>